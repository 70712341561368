import { useLayoutEffect } from "react";
import loadable from "@loadable/component";
import { n5BlogUrl } from "@utils/constants";
import "@styles/index.scss";
import { useI18next } from "gatsby-plugin-react-i18next";

const ArticlesList = loadable(() => import("@organism/ArticlesList"));
const DownloadableContent = loadable(() =>
  import("@organism/DownloadableContent")
);
const Editors = loadable(() => import("@organism/Editors"));

const Blog = () => {

  const { language } = useI18next();
  const lang = language === "pr" ? "pt-br" : language;
  
  useLayoutEffect(() => {
    const newBlogUrl = lang === "es" ? n5BlogUrl : n5BlogUrl + lang + '/';
    window.location.assign(newBlogUrl);
    
  }, []);

  return null;
};

export default Blog;
